import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Box from '../components/primitives/Box'
import Button from '../components/system/Button'
import {
  SalesRegion,
  getCalendarBookingLink,
  useSalesRegion,
} from '../regionUtils'

const regionNames: Record<SalesRegion, string> = {
  'US-East': 'US East',
  'US-West': 'US West',
  EU: 'Europe',
  'US-Small': 'US',
}

function isSalesRegion(region: string | null): region is SalesRegion {
  return region !== null && region in regionNames
}

const calendars = {
  otto: 'https://help.swarmia.com/meetings/otto-hilska/30-minute-demo',
}

export type DemoCalendars = keyof typeof calendars

function PickATimePage({ location }: { location: Location }) {
  const params = new URLSearchParams(location.search)
  const salesRegion = useGetRegion(location)
  const urlCalendar = params.get('calendar')
  const calendarLink =
    urlCalendar && calendars[urlCalendar] ? calendars[urlCalendar] : null
  return (
    <Layout
      justifyCenter
      title="Pick a time"
      description="Pick a meeting time"
      isNew
    >
      {calendarLink ? (
        <CalendarEmbed
          calendarEmbedLink={`${calendarLink}?embed=true&region=${salesRegion}`}
        />
      ) : null}
      {!calendarLink && salesRegion ? (
        <SalesRegionEmbed salesRegion={salesRegion} />
      ) : null}
    </Layout>
  )
}

function CalendarEmbed({ calendarEmbedLink }: { calendarEmbedLink: string }) {
  return (
    <HubspotEmbed
      title="Schedule a demo"
      calendarEmbedLink={calendarEmbedLink}
    />
  )
}

function SalesRegionEmbed({ salesRegion }: { salesRegion: SalesRegion }) {
  const calendarEmbedLink = `${getCalendarBookingLink(
    salesRegion,
  )}?embed=true&region=${salesRegion}`

  return (
    <HubspotEmbed title="Schedule a demo" calendarEmbedLink={calendarEmbedLink}>
      <OtherRegions salesRegion={salesRegion} />
    </HubspotEmbed>
  )
}

function HubspotEmbed({
  title,
  calendarEmbedLink,
  children = null,
}: {
  title: string
  calendarEmbedLink: string
  children?: ReactNode
}) {
  // Defer rendering calendar until the region request has returned:
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          defer
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        paddingX={24}
        paddingTop={32}
        justifyContent="center"
      >
        <Box.h1 font="h4" marginX="auto" display="block" paddingBottom={12}>
          {title}
        </Box.h1>
        <Box
          className="meetings-iframe-container"
          data-src={calendarEmbedLink}
          minHeight={756}
          css="iframe {display: block;}"
        />
        {children}
      </Box>
    </>
  )
}

function OtherRegions({ salesRegion }: { salesRegion: SalesRegion }) {
  const [region1, region2] = Object.keys(regionNames)
    .filter(key => key !== salesRegion)
    .filter(isSalesRegion)

  return (
    <Box
      font="medium"
      marginX="auto"
      paddingBottom={48}
      css={`
        margin-top: -76px;
      `}
    >
      Showing available times with our 📍{' '}
      <Box.span fontWeight={500}>{regionNames[salesRegion]}</Box.span> team. See
      also times in{' '}
      <Button variant="link" href={getRegionHref(location, region1)}>
        {regionNames[region1]}
      </Button>{' '}
      and{' '}
      <Button variant="link" href={getRegionHref(location, region2)}>
        {regionNames[region2]}
      </Button>
      .
    </Box>
  )
}

function getRegionHref(location: Location, region: SalesRegion) {
  const params = new URLSearchParams(location.search)
  params.set('region', region)
  return `?${params.toString()}`
}

function useGetRegion(location: Location) {
  const params = new URLSearchParams(location.search)
  const defaultSalesRegion = useSalesRegion(params.get('numberOfDevs') || '')
  const urlRegion = params.get('region')
  return isSalesRegion(urlRegion) ? urlRegion : defaultSalesRegion
}

export default PickATimePage
